col{
margin-top:1em !important;
}

:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: OpenSans;
    --alternative-font-family: Roboto, Helvetica, Arial, sans-serif;
    
    /* change settings for headings */
    --header-bg-color: black;
    --header-padding: 0.5em 0;
    
    /* change colors for topmenu */
    --topmenu-bg: var(--link-color);
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    
    /*Colors & Backgrounds*/
    --text-color: #444;
    --heading-color: #000;
    --link-color: #E42232;
    --button-bg-color: #E42232;
    --link-hover-color: #000;
    --alternative-color: #ff0000;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";
@import "/fontmodules/Roboto.css";
/*@import "/cssmodules/animate.css";*/


/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightgray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}


/* Header
---------------------------------------------------------*/
header {
    position:fixed;
    top:0;
    left:0;
    right:0;
    width:100%;
    z-index:1002;
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

header .container {
    padding:0 0.5em;
}

.homepage header {
    background:transparent;
}

header a {
    color:white;
}

header.change {
    background:rgba(0,0,0, 0.7);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.homepage .logotop {
    display:none;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

header.change .logotop {
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    display:block;
}
  

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:white;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:#fff;
    z-index:100;
}

.menu .sub-menu-items li a {
    margin:0;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}


/* bildspel
---------------------------------------------------------*/

.start-slider{
    position:relative;
}

.textslider {
    position:absolute;
    top:35vh;
    right:17%;
}

.textslider .container {
    text-align:right;
}


.textslider-inner {
    text-align:right;
    max-width:400px;
    width:100%;
    margin:0;
    color:white;
    font-size: calc(1 * var(--scale-factor) - 0.5rem);
}

.textslider-inner a {
    display:inline-block;
    border-bottom:#eee solid 1px;
    padding-bottom:1em;
}

.textslider-inner h2, .textslider-inner h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
}

.slick-substitute > div {
    height:100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
}

.slick-substitute {
    overflow:hidden;
}

.slider-img {
  -webkit-animation: kenburns 100s infinite linear;
  animation: kenburns 100s infinite linear;
}

.startinsta .slick-prev:before, .slick-next:before {
    color: #222;
}

.startinsta .slick-track {
    display: flex;
    flex-direction: row;
}   

.startinsta .slick-slider {
    margin:0 auto;
    position: relative;
}

@keyframes kenburns {
  0% {
    transform: scale(1) rotate(0deg)
  }
  50% {
    transform: scale(1.4)
  }
}

.scrolldown {
    position:absolute;
    left:0;
    right:0;
    bottom:30px;
    z-index:100;
    width:100%;
    color:red;
}

.scrolldown .container {
    text-align:center;
}

.scrolldown .container a {
    display:inline-block;
}

.scrolldown .container a svg.fa-icon {
    fill:var(--link-color);
    height:auto;
    width:6em;  
}

.scrolldown .container a svg.fa-icon:hover {
    fill:#eee;
}

/* News
---------------------------------------------------------*/
.introtext-content {
    color:white; 
    text-align:center;
}

.introtext-content h3 {
    font-size: calc(1 * var(--scale-factor)rem);
    color:white;
}

/* Content-startpage
---------------------------------------------------------*/
.block-2  {
    padding:2em 0;
}

.block-2 .container .inner-content {
    max-width:800px;
    margin:0 auto;
}

/* startboxar */
.startbox {
    margin-top:3em;
}

.startbox-wrapper {
    text-align:center;
}

.startbox-wrapper .pop-img svg.fa-icon {
    height:auto;
    width: 4em;
    fill: var(--alternative-color);
    vertical-align:middle !important;
}

.startbox-wrapper h2 {
    margin-top:1em;
}

.startbox-wrapper a {
    color:var(--link-color);
}

.startbox a {
    display:block;
    text-decoration:none;
}

.startbox a:hover {
    color:#6E667B;
}

.startbox-wrapper {
    padding:2em;
    border:1px solid #e8e8e8;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.startbox-wrapper:hover {
    border:1px solid #c3c3c3;
}

.pop-img img {
    display:block;
}

.readmore {
    margin-top:0.5em;
    border-top:#c3c3c3 solid 1px;
}
.readmore > div {
    padding-top:0.5em;
    color:#222;
}

.startbox-wrapper .readmore svg.fa-icon {
    height:auto;
    width: 1em;
    fill: var(--link-color);
    vertical-align:middle !important;
}
/* zoomboxar */
.zoombox {
    height:20vw;
    overflow:hidden;
    position:relative;
    color:#fff;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom:0;
    box-shadow: 0px 0px 0px 2px #fff;
}

a.zoombox {
    -webkit-transition: color 3s ease-in-out 0s;
    transition: color 3s ease-in-out 0s;
}

.zoombox:hover {
    color: rgba(255, 255, 255, 1);
}

.zoombox_innerdiv {
    height:100%;
    width:100%;
    background-position: 50% 50%;
    background-size:cover;
    transform: scale(1); position:absolute;
    transition: transform 3s ease-in 0s;
}

.zoomboxar > a:hover .zoombox_innerdiv {
    transform: scale(1.2);
    transition: transform 3s ease-in 0s;
} 

.zoombox_textdiv {
    height:100%;
    width: 100%;
    overflow:hidden;
    margin:0;  padding: 0px 2em;
    text-align:center;
    position:absolute;
}

.zoombox-inner {
    width:100%;
    height:100%;
}

.zoombox h2 {
    display:block;
    width:100%;
    position: relative;
    text-align: center;
    font-size: calc(1 * var(--scale-factor) + 0.7rem);
    font-weight:700;
    color: rgba(255, 255, 255, 0.8);
    -webkit-transition: color 1s ease-in-out 0s;
    transition: color 1s ease-in-out 0s;
    text-shadow:0px 0px 20px rgba(0, 0, 0, 0.4);
}

.zoombox h2:hover, .zoombox h2:hover + .zoombox-teaser {
    color: rgba(255, 255, 255, 1);
}

.zoombox .zoombox-teaser {
    color: rgba(255, 255, 255, 0);
    -webkit-transition: color 1s ease-in-out 0s;
    transition: color 1s ease-in-out 0s;
    min-height:70px;
}

.zoombox .zoombox-teaser:hover {
    color: rgba(255, 255, 255, 1);
}

.zoombox .zoombox-teaser .more {
    display:inline-block;
    margin-top:1em;
    font-weight:700;
    text-decoration:underline;
}



/* block-3 */
.block-3 {
    position:relative;
    padding:1.5em 0;
}

.news-heading {
    margin-bottom:2em;
    padding-bottom:2em;
}

.news-heading .container {
    text-align:center;
    border-bottom:#d8d8d8 solid 1px;
    max-width:800px;
    margin:0 auto;
}

.news-heading .container h3 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

.news-content-img {
    position: relative;
    display: block;
    background-size:cover;
    background-repeat:no-repeat;
}

@media (min-width: var(--breakpoint-s)) {
    .news-block {
        background-color: #fff;
        overflow: hidden;   
    }

    .news-block-inner {
        overflow-y:scroll;
        height:548px;
    }
}

.news-post p  {
    margin-top:0;
}

.news-img .likes {
    font-size:0.9rem;
}

.news-content .facebook-link {
    display:inline-block;
    text-decoration:none;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.news-content svg.fa-icon {
    fill: #4C66A4;
    height:auto;
    width:3em;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.news-content svg.fa-icon:hover {
    fill: #000;
}


/* Blog
---------------------------------------------------------*/

.itemextrainfo {
    font-size: .85rem;
}

.newsitemwrapper {
    margin-bottom:1em;
}

.latestpost_list, .archive_list {
    list-style:none;
    padding:0;
    margin-top:0;
}

.latestpost_list li, .archive_list li {
    padding:0.2em 0;
}

.pagination {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    margin-top:21px;
    width:100%;
}

.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: var(--link-color);
}

/* products
---------------------------------------------------------*/

.header-divider.margin > * {
    margin-top: 0px;
    margin-bottom: 0px;    
}

.relatedrecords.margin > * {
    margin:0.5em;
}

.product-item {
    position:relative;
    border:#d8d8d8 solid 1px;
}

.prduct-price, .prduct-price a {
    color:white;
}

.product-flag-container {
    position:absolute;
    top:19px;
    right:19px;
    z-index:10;
}

.product-flag {
    background:#f54905;
    position:relative;
    display:block;
    padding:0.5em 2em;
    color:white;
}

.product-flag:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -12px;
    right: 17px;
    width: 0;
    height: 0;
    border-right: 15px solid transparent;
    border-top: 13px solid #f54905;
}

.team-position h3 {
    color:#E42232;
}

.prod-img {
    border:#d8d8d8 solid 1px;
}

.mfp-iframe-holder .mfp-content {
    max-width:1100px;
}

.mfp-iframe-scaler iframe {
    background:white;
}

    


/* Content
---------------------------------------------------------*/

.content h1 {
    margin:0.2em;
}

body.kontakt {
    background:#fff;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

figure {
    margin:0;
}

.top-img {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height:400px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

/* Calendar
---------------------------------------------------------*/
#slideout {
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    color: #333;
    position: fixed;
    top: 50%;
    z-index: 10000;
    transition: transform .3s;
    transform: translateX(-100%) translateY(-50%);
}

#slideout.on {
  transform: translateX(-0%) translateY(-50%);
}

.showCal {
    position: absolute;
    top: 50%;
    right: 0px;
    z-index: 10000;
    transform: rotate(90deg) translateY(-229%) translateX(-21%);
    background: var(--button-bg-color);
    color:white;
    border:none;
    border-radius:0px;
    padding: 10px 30px;
}

.calendar-inner {
    padding:2em;
}

/* Gallery
---------------------------------------------------------*/

.gallerier main {
    margin-top:80px;
}

.c-right {
    font-style:italic;
}

.multicolumns {
    column-count: 3;
    column-gap: .3rem;
}

.multicolumns div {
    padding:10px;
    
}

.gallery-item {
    position:relative;
}

.gallery-item a {
    position:relative;
    display:block;
    width:100%;
    overflow:hidden;
    transition: 0.5s ease;
}
@media (min-width:500px){
.gallery-item a:hover:before {
    position:absolute;
    top:0;
    content:"🔍";
    background:rgba(0,0,0,0.6);
    width:100%;
    height:100%;
    z-index:100;
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;
    font-size:1.6rem;
    transition: 0.5s ease;
    
}
}

.gallery-item a img {
    display:block;
}



/* team
---------------------------------------------------------*/

.team-item {
    position:relative;
    box-shadow: 0 0 1px #ccc;
    transition: 0.5s ease;
}

.team-item:hover {
    position:relative;
    box-shadow: 0 0 1px #222;
}

.prduct-price, .prduct-price a {
    color:white;
}

.position-flag-container {
    position:absolute;
    top:19px;
    right:19px;
    z-index:10;
}

.position-flag {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
    position:relative;
    display:block;
    padding:0.5em 2em;
}

.position-flag:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -12px;
    right: 17px;
    width: 0;
    height: 0;
    border-right: 15px solid transparent;
    border-top: 13px solid var(--topmenu-bg);
}


/* Social Button CSS 
---------------------------------------------------------*/

.share-btn {
    display: inline-block;
    color: #ffffff;
    border: none;
    padding: 0.5em;
    width: 4em;
    outline: none;
    text-align: center;
    text-decoration:none;
    border-left:#fff  solid 2px;   
}

.share-btn:hover, .share-btn:hover svg.fa-icon {
    opacity:0.8;
    color:white;
}

.share-btn:active {
  position: relative;
  top: 2px;
  box-shadow: none;
  color: #e2e2e2;
  outline: none; 
}

.share-btn svg.fa-icon {
    fill: #fff;
}

.share-btn span {
    display:inline-block;
}

.share-btn.twitter     { background: #55acee; }
.share-btn.google-plus { background: #dd4b39; }
.share-btn.facebook    { background: #3B5998; }
.share-btn.linkedin    { background: #4875B4; }
.share-btn.email       { background: #444444; }




/* Footer
---------------------------------------------------------*/

.newsletter {
    border-bottom:#333 solid 1px;
}

footer {
    color:white;
}

footer h4 {
    color:white;
    letter-spacing:0;
    font-size:1.4rem;
}

footer a {
    color:white;
    text-decoration:none;
}

footer a:hover {
    color:#E12231;
}

footer svg.fa-icon {
    fill:white;
    height:calc(1 * var(--scale-factor)rem);
}

footer svg.fa-icon:hover {
    fill: #E42232;
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.intenditfooter >* {
    margin: var(--margin);
}

footer .contactinfo p {
    margin:0;
}

.partners ul, .sponsorer ul {
    list-style:none;
    padding:0;
    text-align:center;
}


.partners li img, .sponsorer li img {
    display:block;
    margin:0 auto;
}

.sida.Sponsor .partners {
    display:none !important;
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}



/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

.button, button[type=submit], input[type=submit] {
    margin:0;
    background:var(--button-bg-color);
    border:var(--button-bg-color) solid 1px;
    color:white;
    border-radius:0px;
}

.button:hover, button[type=submit]:hover, input[type=submit]:hover {
    background: var(--link-hover-color);
    border:var(--link-hover-color) solid 1px;
    color:white;
}


img {
    max-width: 100%;
    height: auto !important;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
    margin:0 auto;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    letter-spacing:-1px;
    color: var(--heading-color);
    margin:0 0 0.3em 0;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

h4 {
    font-size: 1.1rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}
p{
    margin:0 0 1em 0;
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

.localeswitcher.active {
    display:none;
}

.nyhet .margin > *{
    margin-top:0;
}

/* Media Queries
---------------------------------------------------------*/
 
@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#fff !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    .menu li, .menu li ul, .menu li:hover ul {
        margin:0;
    }
    .menu li a {
        color:black;
    }
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border-color:white;
    }
    .top-img {
        min-height:30vh;
    }
    .slider-nav {
        display:none !important;
    }
    img {
        height:auto !important;
    }

}

@media (max-width: var(--breakpoint-s)) {
    .textslider {
        position:absolute;
        top:15vh;
        right:0%;
    }
    .textslider .container {
        text-align:right;
    }
    .textslider-inner {
        text-align:right;
        max-width:300px;
        width:100%;
        margin:0;
        color:white;
        font-size: calc(1 * var(--scale-factor) - 0.5rem);
    }
    footer .container {
        text-align:center;
    }
    .multicolumns {
        column-count: 2;
        column-gap: .3rem;
    }
}

@media (max-width: var(--breakpoint-xs)) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:100vw;
    }
    .multicolumns {
        column-count: 1;
        column-gap: .3rem;
    }
}
